import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, HostListener } from '@angular/core';
import { ProtocolsService } from 'apps/web/src/app/services';

@Component({
    selector: 'alii-web-save-patient-external-service',
    templateUrl: './save-patient-external-service.html',
    styleUrls: ['./save-patient-external-service.scss']
})

export class SavePatientExternalServiceComponent implements OnInit, OnDestroy {

    @Input() saveToExternalService: number;
    @Input() documentId: string;
    @Input() populationId: string;

    isSaving = false;
    hasSaved = false;

    constructor(private protocolsService: ProtocolsService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        window.addEventListener('beforeunload', this.warnIfUnsaved);
    }

    save(event: MouseEvent) {
        event.preventDefault();

        if (this.isSaving) return;

        this.isSaving = true;
        this.cdr.detectChanges();

        this.protocolsService.savePatientToExternalService(this.documentId, this.populationId)
            .subscribe(() => {
                this.isSaving = false;
                this.hasSaved = true;
                this.cdr.detectChanges();
            }, () => {
                this.isSaving = false;
                this.cdr.detectChanges();
            });
    }

    @HostListener('window:beforeunload', ['$event'])
    warnIfUnsaved(event: BeforeUnloadEvent) {
        if (this.hasSaved == false) {
            event.preventDefault();
            // Chrome requires returnValue to be set
            // but this value isn't actually read
            // it just uses a default value
            // we can't set the message anymore in modern browser versions.
            // It will be something like this:
            // "Leave site?"
            // "Changes you made may not be saved."
            event.returnValue = ' ';
        }
    }

    ngOnDestroy() {
        // Remove beforeunload event listener
        window.removeEventListener('beforeunload', this.warnIfUnsaved);
    }
}