/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { LanguageService } from '../../../services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import * as fromStore from '../store';
import { StartingPageActionDashboard, StartingPageRO } from '../models';
import { NavigationService } from '@alii-web/services';
import { Language } from '../../../models/language.model';
import { ActivatedRoute, Router } from '@angular/router';

const cn = 'StartingPageComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-starting-page',
    templateUrl: './starting-page.component.html',
    styleUrls: ['./starting-page.component.scss']
})
export class StartingPageComponent implements OnInit, OnDestroy {
    data$: Observable<StartingPageRO>;
    loading$: Observable<boolean>;
    error$: Observable<any>;
    dashboards$: Observable<StartingPageActionDashboard[]>;
    setDashboardLoading$: Observable<boolean>;

    showBanner = true;
    loadingStartingPage: boolean;

    numberOfDashboards: number;

    subscriptions: Subscription[] = [];
    
    
    public rappView: string;

    constructor(
        private sanitizer: DomSanitizer,
        private store: Store<fromStore.StartingPageFeatureState>,
        private navigationService: NavigationService,
        private permissionsService: NgxPermissionsService,
        private languageService: LanguageService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,

    ) {}

    ngOnInit() {
        this.data$ = this.store.select(fromStore.getStartingPageGetLoaded);
        this.loading$ = this.store.select(fromStore.getStartingPageGetLoading);
        this.error$ = this.store.select(fromStore.getStartingPageGetError);
        this.dashboards$ = this.store.select(fromStore.getStartingPageDashboards);
        this.setDashboardLoading$ = this.store.select(fromStore.getSetDashboardLoading);
        let language = localStorage.getItem('language') 
        this.languageService.set(language as Language);
        // OK, this is a hack: before we would only get data from the call if there was no data. Now we always do it
        // so we first get data from the store, then update it immediately
        this.data$.pipe(take(1)).subscribe(data => this.store.dispatch(new fromStore.StartingPageGet()));

        // Navigation tabs
        this.subscriptions.push(
            this.store.select(fromStore.getStartingPageTabs).subscribe(tabs => this.navigationService.setTabs(tabs))
        );

        // Permissions
        this.subscriptions.push(this.data$.subscribe(data => this._handlePermissions(data)));

        /*
        disabled on request from rapp, commeneted it out in case they their mind
        this.rappView = window.localStorage.getItem('rappView');
        */

        this.route.queryParams.subscribe(params => {
            const patientId = params['patientId'];
            if (patientId) 
            {
              sessionStorage.setItem('populationId', patientId);
            }
            const isGerimedica = params['isGerimedica']; 
            if (isGerimedica === "true")
            {
                const gerimedicaId = params['externalId'];
                
                sessionStorage.setItem('isGerimedica', "true");
                sessionStorage.setItem('gerimedicaId', gerimedicaId);

                this.router.navigate(["/home"]);
            }
          });      
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    handleSelectDashoard(event: any) {
        const id = event.toString();
        this.store.dispatch(new fromStore.SetDashboard({ id }));
    }

    closeBanner() {
        this.showBanner = false;
    }

    trackByFn = (index, item) => item.id || index;


    public setRappView(view: string) {
        this.rappView = view;
        /*
        disabled on request from rapp, commeneted it out in case they their mind
        window.localStorage.setItem('rappView', view)
        */
    }

    private _handlePermissions(data: StartingPageRO): void {
        if (data && data.actions && Array.isArray(data.actions)) {
            data.actions
                .filter(action => action.value)
                .map(action => action.action)
                .forEach(permission => this.permissionsService.addPermission(permission));
            data.actions
                .filter(action => !action.value)
                .map(action => action.action)
                .forEach(permission => this.permissionsService.removePermission(permission));
        }
    }

    safeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    // Patient stuff, saved for later.
    handleEventBus(event) {
        console.warn(`${cn} handleEventBus() event='${JSON.stringify(event)}' disabled`);
        // const { type, payload } = event;
        // switch (type) {
        //     case 'handleSubmitAddPatient':
        //         this.handleSubmitAddPatient(payload);
        //         break;
        //     case 'handleIncludeAddPatient':
        //         this.handleIncludeAddPatient(payload);
        //         break;
        //     case 'handleRemovePatient':
        //         this.handleRemovePatient(payload);
        //         break;
        //
        //     default:
        //         break;
        // }
    }

    // handleSubmitAddPatient(payload) {
    //     const { protocolId, title } = payload;
    //     const dashboardPatient = {
    //         id: '',
    //         protocol: {
    //             id: protocolId,
    //             title: ''
    //         },
    //         population: {
    //             id: null,
    //             name: title
    //         }
    //     };
    //     this.store.dispatch(new fromStore.AddDashboardPatient({ dashboardPatient }));
    // }

    // handleIncludeAddPatient(payload) {
    //     const { caseId, protocolId } = payload;
    //     this.store.dispatch(new fromStore.AddDashboardPatientToProtocol({ patientId: caseId, protocolId }));
    // }

    // handleRemovePatient(payload) {
    //     const { patientId } = payload;
    //     this.store.dispatch(new fromStore.DeleteDashboardPatient({ id: patientId }));
    // }
}
