import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit, OnDestroy, NgModuleRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';

import { Store } from '@ngrx/store';
import { Observable, merge, Subscription, fromEvent, of, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap,take, tap } from 'rxjs/operators';

import * as fromStore from './../../store';
import * as fromService from '../../../../services';

import { Protocol } from '../../../../models/protocol.interface';
import { Literatures } from '../../../../models/literature.interface';
import { KeyQuestionImport } from '../../../../models/keyquestion-import.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import {
    CompareModelsModalComponent,
    ModelHelpTextModalComponent,
    OutcomeEditModalComponent,
    OutcomeTreeviewModalComponent
} from '../../entry-components';
import { User } from '../../../../models/user.interface';
import { GetModelRO, GetOutcomeRO } from '../../../../models/outcome.interface';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { ParagraphsService } from 'apps/web/src/app/services/par.service';


const cn = 'ProtocolsDetailComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocols-detail',
    templateUrl: './protocols-detail.component.html',
    styleUrls: ['./protocols-detail.component.scss']
})
export class ProtocolsDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    loading$: Observable<boolean>;
    loadingProtocol$: Observable<boolean> = this.store.select(fromStore.getSelectedLoading);
    loadingModel$: Observable<boolean> = this.store.select(fromStore.getModelLoading);

    loadingCategories$: Observable<boolean> = this.store.select(fromStore.getProtocolCategoriesLoading);

    channelMessages$: Observable<any> = this.store.select(fromStore.getChannelMessages);

    views$: Observable<any> = this.store.select(fromStore.getViews);
    currentView$: Observable<any> = this.store.select(fromStore.getCurrentView);
    defaultView$: Observable<any> = this.store.select(fromStore.getDefaultView);

    protocol$: Observable<Protocol> = this.store.select(fromStore.getSelected);
    populations$: Observable<any> = this.store.select(fromStore.getProtocolPopulations);
    literatures$: Observable<Literatures>;
    currentVersion$: Observable<any> = this.store.select(fromStore.getCurrentVersion);
    draftVersion$: Observable<any> = this.store.select(fromStore.getDraftVersion);
    deprecatedVersions$: Observable<any[]> = this.store.select(fromStore.getDeprecatedVersions);
    storedDraftVersions$: Observable<any[]> = this.store.select(fromStore.getStoredDraftVersions);

    keyQuestionImportList$: Observable<KeyQuestionImport[]> = this.store.select(fromStore.getKeyQuestionImport);
    gradeAssessment$: Observable<any> = this.store.select(fromStore.getGradeAssessment);
    paragraphsFiles$: Observable<any> = this.store.select(fromStore.getParagraphsFiles);

    modelFindings$: Observable<any> = this.store.select(fromStore.getModelFindingEntries);
    modelOutcomes$: Observable<any> = this.store.select(fromStore.getModelOutcomeEntries);
    modelTagList$: Observable<any> = this.store.select(fromStore.getModelTagListEntries);

    pubmeds$: Observable<any> = this.store.select(fromStore.getPubmeds);

    protocolArticle$: Observable<any> = this.store.select(fromStore.getProtocolArticleSelected);
    populationId$: Observable<any> = this.store.select(fromStore.loadPopulationId);

    users$: Observable<User[]> = of([]);

    modelUpdateBySheetId$: Observable<any> = this.store.select(fromStore.getUpdateModelBySheetId);
    linkToProtolId$: Observable<any> = this.store.select(fromStore.getLinkToProtocol);
    lastIntegrationSurveyDate$: Observable<Date> = this.store.select(fromStore.getLastIntegrationSurveyDate);
    saveToExternalService$: Observable<number> = this.store.select(fromStore.getSaveToExternalService);

    id: string;
    paragraphSelectedId: string;
    versionId: string;
    version: string;
    populationId = '';
    messages: any;
    isPrint = false;
    pane = 'content';
    viewByPopulation: boolean;
    reloadView: boolean;

    availableViews$: Observable<any>;

    // Scroll events.
    private subscriptions: Subscription[] = [];
    private scrollPos = { x: 0, y: 0 };
    private viewsLength: number;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<fromStore.ProtocolsFeatureState>,
        private CommentService: fromService.CommentService,
        private ParagraphsService: ParagraphsService,
        private http: HttpClient,
        private permissionsService: NgxPermissionsService,
        private modalService: NgbModal
    ) {
        router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.id = params.get('id');
        });

        // if(sessionStorage.getItem('lastProtocol') != this.id) {
        //     sessionStorage.removeItem('populationId')
        // }

        sessionStorage.setItem('lastProtocol', this.id);

        this.pane = 'content';

        const populationIdSession = window.sessionStorage && sessionStorage.getItem('populationId');
        if (populationIdSession) {
            this.populationId = populationIdSession;
            this.store.dispatch(new fromStore.SetPopulationId({ populationId: this.populationId }));
        }

        this.route.queryParamMap.subscribe(queries => {
            const versionId = queries.get('versionId');
            const version = queries.get('version');
            const populationIdFromPath = queries.get('populationId');
            this.viewByPopulation = queries.get('viewByPopulation') === 'yes';

            if (populationIdFromPath) {
                this.populationId = populationIdFromPath;
                this.store.dispatch(new fromStore.SetPopulationId({ populationId: this.populationId }));
            }

            this.versionId = versionId ? versionId : null;
            if (this.versionId) {
                this.version = 'archived';
            } else if (version === 'Preview') {
                this.version = 'Preview';
            } else {
                this.version = version && version === 'Draft' ? 'Draft' : 'Current';
            }
        });

        this.loading$ = merge(this.loadingCategories$, this.loadingProtocol$);
        this.store.dispatch(
            new fromStore.LoadProtocol({
                protocolId: this.id,
                versionId: this.versionId,
                version: this.version,
                populationId: this.populationId,
                showAllOutcomes: !!this.route.snapshot.queryParams.showAllOutcomes,
                ...(this.viewByPopulation && { viewByPopulation: true })
            })
        );

        this.availableViews$ = this.views$.pipe(
            map(views => views.map(view => view['name'])),
            tap(views => (this.viewsLength = views.length))
        );

        // If the store is empty, or if we try to load another protocol (/-version),
        // apply the correct view.
        this.protocol$.pipe(take(1)).subscribe(protocol => {
            if (
                protocol === null ||
                (protocol && protocol.id !== this.id) ||
                (protocol && protocol.protocol_version !== this.version)
            ) {
                this.reloadView = true;
            }
        });

        this.defaultView$.pipe().subscribe(defaultView => {
            if (this.reloadView && defaultView) {
                const viewForProtocol =
                    window.sessionStorage && sessionStorage.getItem('view-' + this.id + '-' + this.version);
                const view = viewForProtocol ? viewForProtocol : defaultView;
                this.handleSwitchView(view);
                this.reloadView = false;
            }
        });
        this.protocol$.subscribe(protocol => {
            if (protocol) {
                let actions = protocol.protocol_actions.reduce((acc, p) => {
                    return [...acc, p.action];
                }, []);

                if (!!protocol.show_settings && protocol.show_settings) {
                    actions.push('show_settings');
                }

                this.permissionsService.addPermission(actions);
            }
        });
    }

    ngAfterViewInit() {
        // Attempt to access the 'page__content' element every 200ms until either it is found or max time reached.
        const TIMEOUT = 200;
        const MAX = 5000;
        let msecs = 0;
        // TODO: Replace with RXJS timer take until which is more elegant.
        const interval = setInterval(() => {
            const sel = 'page__content';
            const el = document.getElementsByClassName(sel);
            if (el && el[0]) {
                // Found.
                this._handleScroll(el[0]);
                clearInterval(interval);
            } else {
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    console.warn(`${cn} ngAfterViewInit() Cannot find element with sel='${sel}'`);
                    clearInterval(interval);
                }
            }
        }, TIMEOUT);
    }

    _handleScroll(elScroll: Element) {
        this.subscriptions.push(
            this.store.select(fromStore.getCurrentViewScroll).subscribe(
                v =>
                    v &&
                    setTimeout(() => {
                        const {
                            name,
                            paragraphId,
                            scrollPos: { x, y }
                        } = v;
                        if (name === 'text' && paragraphId) {
                            // Scroll heading or question into view
                            let payload = {fragment: 'ppd-'+paragraphId};
                            ['heading', 'question'].forEach(anchorType => {
                                const el = document.getElementById(`${anchorType}-${paragraphId}`);
                                if (el) {
                                    el.scrollIntoView(true);
                                }
                            });
                            this.storeParagraphClick(payload)
                        } else {
                            // Restore previous scroll position
                            if (typeof elScroll.scroll === 'function') {
                                elScroll.scroll(x, y);
                            } else {
                                // IE11 doesn't support scroll!
                                elScroll.scrollLeft = x;
                                elScroll.scrollTop = y;
                            }
                        }
                    }, 100)
            )
        );
        if (this.viewsLength > 1) {
            // Save and restore scroll positions only if there are multiple views.
            this.subscriptions.push(
                fromEvent(elScroll, 'scroll')
                    .pipe(debounceTime(500), distinctUntilChanged())
                    .subscribe(() => {
                        this.scrollPos = { x: elScroll.scrollLeft, y: elScroll.scrollTop };
                    })
            );
        }
    }

    ngOnDestroy() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    storeParagraphClick(payload) {
        const endpointUrl = `${environment.baseUrl}/api/statistics/store.vm?protocolId=${this.id}&par=${payload.fragment}`;
        this.http.get(endpointUrl).subscribe();
    }


    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleStoreEvent':
                this.storeParagraphClick(payload);
                break;
            case 'handleResetPatient':
                this.resetPatient();
                break;
            case 'handleUploadFile':
                this.handleUploadFile(payload);
                break;
            case 'handleDeleteFile':
                this.handleDeleteFile(payload);
                break;
            case 'open-print':
                this.isPrint = true;
                break;
            case 'close-print':
                this.isPrint = false;
                break;
            case 'handleUpdateSetting':
                this.handleUpdateSetting(payload);
                break;
            case 'handleParagraphSelected':
                this.handleParagraphSelected(payload);
                break;
            case 'handleClickParagraphCard':
                this.handleParagraphSelected(payload);
                break;
            case 'handleOpenByVersion':
                this.handleOpenByVersion(payload);
                break;
            case 'handleOpenCompareVersion':
                this.handleOpenCompareVersion();
                break;
            case 'onSelectOutcome':
                this.onSelectOutcome(payload);
                break;
            case 'onSelectOutcomeList':
                this.onSelectOutcomeList(payload);
                break;
            case 'onShowMoreOutcomes':
                this.onShowMoreOutcomes(payload);
                break;
            case 'handleUpdateParTitle':
                this.handleUpdateParTitle(payload);
                break;
            case 'handleAddPar':
                this.handleAddPar(payload);
                break;
            case 'handleUpdateParToModel':
                this.handleUpdateParToModel(payload);
                break;
            case 'handleUpdateKeyQuestion':
                this.handleUpdateKeyQuestion(payload);
                break;
            case 'handleChangeTypeOfPar':
                this.handleChangeTypeOfPar(payload);
                break;
            case 'handleDoneEditOrder':
                this.handleDoneEditOrder(payload);
                break;
            case 'handleUpdateParPosition':
                this.handleUpdateParPosition(payload);
                break;
            case 'handleCopyProtocol':
                this.handleCopyProtocol(payload);
                break;
            case 'handleLoadKeyQuestion':
                this.handleLoadKeyQuestion(payload);
                break;
            case 'handleSubmitConnectKeyQuestion':
                this.handleSubmitConnectKeyQuestion(payload)
                break;
            case 'handleImportElementToSummary':
                this.handleImportElementToSummary(payload);
                break;
            case 'handleDeletePar':
                this.handleDeletePar(payload);
                break;
            case 'handleAddFinding':
                this.handleAddFinding(payload);
                break;
            case 'handleRemoveFinding':
                this.handleRemoveFinding(payload);
                break;
            case 'handleAddOutcome':
                this.handleAddOutcome(payload);
                break;
            case 'handleEditModelOutcome':
                this.handleEditModelOutcome(payload);
                break;
            case 'handleUpdateQuestionTitle':
                this.handleUpdateQuestionTitle(payload);
                break;
            case 'handleAddValueOption':
                this.handleAddValueOption(payload);
                break;
            case 'handleUpdateValueOption':
                this.handleUpdateValueOption(payload);
                break;
            case 'handleRemoveValueOption':
                this.handleRemoveValueOption(payload);
                break;
            case 'handleAddSliderOption':
                this.handleAddSliderOption(payload);
                break;
            case 'handleUpdateSliderOption':
                this.handleUpdateSliderOption(payload);
                break;
            case 'handleAddComment':
                this.handleAddComment(payload);
                break;
            case 'handleReplyComment':
                this.handleReplyComment(payload);
                break;
            case 'handleMarkComment':
                this.handleMarkComment(payload);
                break;
            case 'handleOpenPane':
                this.handleOpenPane(payload);
                break;

            case 'handleUpdatePopulationName':
                this.handleUpdatePopulationName(payload);
                break;

            case 'handleUpdateCopiedPopulationName':
                this.handleUpdateCopiedPopulationName(payload);
                break;

            case 'handleSubmitProtocolActionFlow':
                this.handleSubmitProtocolActionFlow(payload);
                break;

            case 'handleClickIncludeSource':
                this.handleClickIncludeSource(payload);
                break;

            case 'handleClickExcludeSource':
                this.handleClickExcludeSource(payload);
                break;

            case 'handleGetGradeQuestions':
                this.handleGetGradeQuestions(payload);
                break;

            case 'handleLoadEvidence':
                this.handleLoadEvidence(payload);
                break;

            case 'handleSelectGradeQuestions':
                this.handleSelectGradeQuestions(payload);
                break;

            case 'handleDeleteGrade':
                this.handleDeleteGrade(payload);
                break;

            case 'handleSubmitUpdateGrade':
                this.handleSubmitUpdateGrade(payload);
                break;

            case 'handleSubmitCreateGrade':
                this.handleSubmitCreateGrade(payload);
                break;

            case 'handleGetPubmedSearch':
                this.handleGetPubmedSearch(payload);
                break;

            case 'handleAddNonPubmedReference':
                this.handleAddNonPubmedReference(payload);
                break;

            case 'handleAddReference':
                this.handleAddReference(payload);
                break;

            case 'handleImportModelBySheetId':
                this.handleImportModelBySheetId(payload);
                break;

            case 'handleUpdateModelBySheetId':
                this.handleUpdateModelBySheetId(payload);
                break;

            case 'handleCreateCalculationFormula':
                this.handleCreateCalculationFormula(payload);
                break;

            case 'handleLoadProtocolArticle':
                this.handleLoadProtocolArticle(payload);
                break;

            case 'handleSwitchView':
                this.handleSwitchView(payload);
                break;

            case 'handleCreatePopulation':
                this.handleCreatePopulation(payload);
                break;

            case 'handleCopyPopulation':
                this.handleCopyPopulation(payload);
                break;

            case 'handleAddPopulation':
                this.handleAddPopulation(payload);
                break;

            case 'handleReplacePopulation':
                this.handleReplacePopulation(payload);
                break;

            case 'handleDeletePopulation':
                this.handleDeletePopulation(payload);
                break;

            case 'onUpdateModel':
                this.handleUpdateModel(payload);
                break;
            case 'resetModel':
                this.handleResetModel(payload);
                break;
            case 'handleShowAllOutcomes':
                this.handleShowAllOutcomes(payload);
                break;
            case 'handleShowOutcomesByPopulationId':
                this.handleShowOutcomesByPopulationId(payload);
                break;
            case 'handleGradeUpdateRowAction':
                this.handleGradeUpdateRowAction(payload);
                break;
            case 'handleUploadFileToGrade':
                this.handleUploadFileToGrade(payload);
                break;
            case 'openHelpText':
                this.handleOpenHelpText(payload);
                break;
            case 'openOutcomeTreeview':
                this.handleOpenOutcomeTreeview(payload);
                break;
            case 'openOutcomeEdit':
                this.openOutcomeEdit(payload);
                break;
            case 'handleOutcomeEdit':
                this.handleOutcomeEdit(payload);
            case 'openUpdatedModel':
                this.handleOpenUpdatedModel(payload);
                break;
            case 'handleReceivedMessage':
                this.handleReceivedMessage(payload);
                break;
            case 'handlePublishMessage':
                this.handlePublishMessage(payload);
                break;
            case 'handleCreateLocalVersion':
                this.handleCreateLocalVersion(payload);
                break;
            case 'handleLinkToProtocol':
                this.handleLinkToProtocol(payload);
                break;
            case 'removeLinkToProtocol':
                this.handleRemoveLinkToProtocol(payload);
                break;
            case 'handleCreateFlowchart':
                this.handleCreateFlowchart(payload);
                break;
            case 'handleDeleteFlowchart':
                this.handleDeleteFlowchart(payload);
                break;
            case 'handleUpdateRecommendationAction':
                this.handleUpdateRecommendationAction(payload);
                break
            case 'handleDeleteRows':
                this.handleDeleteRows(payload)
                break;
            case 'updateReview':
                this.updateReview(payload)
                break;
            case 'handleDeleteRecommendationRow':
                this.handleDeleteRecommendationRow(payload)
                break;
            case 'handleImportReferences':
                this.handleImportReferences(payload)
                break;
            case 'handleUpdateKeyQuestion':
                this.handleUpdateKeyQuestion(payload)
                break;
            default:
                break;
        }
    }

    handleUpdateRecommendationAction(payload) {
        const { protocolId, ppdId, action, parentId, field, value } = payload;
        this.store.dispatch(new fromStore.UpdateParagraph({ protocolId, ppdId, parentId, options: { action, field, value  } }));
    }


    handleDeleteRows(payload) {
        let findingIds = []
        payload.data.forEach(element => {
            findingIds.push(element.id)
        });
        this.store.dispatch(
            new fromStore.RemoveReferenceFromParagraph({
                ppdId: payload.ppdId,
                parentId: payload.parentId,
                findingIds,
                action: 'deleteRow'
            })
        );

    }

    updateReview(payload) {
        this.store.dispatch(
            new fromStore.EditReferenceForParagraph(payload)
        );
    }

    handleDeleteRecommendationRow(payload) {
        let paragraphId = payload.data[0].id
        // for now we are only supporting deleting one row at a time
        this.store.dispatch(
            new fromStore.DeleteParagraph({
                parentId: payload.ppdId,
                ppdId: paragraphId
            })
        );

    }

    resetPatient() {
        this.store.dispatch(new fromStore.SetPopulationId({ populationId: '' }));

        this.router.navigate([], {
            queryParams: {
                populationId: null
            },
            queryParamsHandling: 'merge'
        });

        this.store.dispatch(
            new fromStore.LoadProtocol({
                protocolId: this.id,
                versionId: this.versionId,
                version: this.version,
                populationId: null
            })
        );

        return false;
    }

    handlePublishMessage(payload: any) {
        const { channel, message } = payload;
        this.store.dispatch(new fromStore.PublishMessage({ channel, message }));
    }

    handleReceivedMessage(payload: any) {
        const { uuid } = payload;
        this.store.dispatch(new fromStore.ReceivedMessage({ uuid }));
    }

    handleOpenHelpText(payload: any) {
        const helpText = payload.helpText;
        const modalRef = this.modalService.open(ModelHelpTextModalComponent, { size: 'lg' });
        modalRef.componentInstance.bodyText = helpText;
    }

    openOutcomeEdit(payload: any) {
        const { populationId, outcome, outcomeListSelected, modelId } = payload;
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(OutcomeEditModalComponent, { size: 'lg' });
        modalRef.componentInstance.outcome = outcome
        modalRef.componentInstance.populationId = populationId;
        modalRef.componentInstance.outcomeListSelected = outcomeListSelected;
        modalRef.componentInstance.modelId = modelId;
        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type, payload } = event;
            this.handleOutcomeEdit(payload);
            outcomeListSelected[outcome.id] = true;
        });
    }

    handleOutcomeEdit(payload: any) {
        const { outcomeId, populationId, text, modelId} = payload;
        this.store.dispatch(new fromStore.UpdateOutcomeSummaryText({outcomeId: outcomeId, populationId: populationId, text: text, modelId: modelId}));
        this.modalService.dismissAll();

    }

    handleOpenOutcomeTreeview(payload: any) {
        const { populationId, outcome, updateKeyQuestion } = payload;

        // Use saved response data for given ppdId already cached (in store) or if not present make request.
        // Note: using take(1) causes the observable to complete there no need to unsubscribe on destroy.
        this.store
            .select(fromStore.getAllOutcomeIds)
            .pipe(take(1))
            .subscribe(ids => {
                if (!ids.includes(outcome.id)) {
                    // Not cached so make request to the backend.
                    this.store.dispatch(new fromStore.GetOutcome({ ppdId: outcome.id, populationId }));
                }
                this.modalService.dismissAll();
                const modalRef = this.modalService.open(OutcomeTreeviewModalComponent, { size: 'lg' });
                const notifyGetOutcomeRO$ = new BehaviorSubject<GetOutcomeRO>(null);
                modalRef.componentInstance.data = { outcome, updateKeyQuestion, notifyGetOutcomeRO$ };
                modalRef.componentInstance.eventBus.subscribe(event => {
                    this.handleEventBus(event)
                });
                this.store
                    .select(fromStore.getOutcomeEntry(), outcome.id)
                    .pipe(
                        filter(entry => !!entry),
                        take(1)
                    )
                    .subscribe(getOutcomeRO => notifyGetOutcomeRO$.next(getOutcomeRO));
            });
    }

    handleOpenUpdatedModel(payload: any) {
        const { paragraph, language } = payload;

        // Use saved response data for given ppdId already cached (in store) or if not present make request.
        // Note: using take(1) causes the observable to complete there no need to unsubscribe on destroy.
        this.store
            .select(fromStore.getAllModelIds)
            .pipe(take(1))
            .subscribe(ids => {
                if (!ids.includes(paragraph.id)) {
                    // Not cached so make request to the backend.
                    this.store.dispatch(new fromStore.GetModel({ ppdId: paragraph.id }));
                }
                this.modalService.dismissAll();
                const modalRef = this.modalService.open(CompareModelsModalComponent, {
                    windowClass: 'compare-models-modal',
                    size: 'lg'
                });
                const title =
                    paragraph.differencesModal && paragraph.differencesModal.title
                        ? paragraph.differencesModal.title
                        : 'Model gewijzigd';
                const text =
                    paragraph.differencesModal && paragraph.differencesModal.text
                        ? paragraph.differencesModal.text
                        : 'Dit model is gewijzigd. Controleer de uitkomsten voor u publiceert.';
                const notifyGetModelRO$ = new BehaviorSubject<GetModelRO>(null);
                modalRef.componentInstance.data = {
                    notifyGetModelRO$,
                    language,
                    title,
                    text,
                    level: +paragraph.level,
                    paragraphs: {
                        previous: {
                            title: paragraph.previous_title,
                            text: paragraph.previous_text
                        },
                        current: {
                            title: paragraph['protocol_paragraph.title'],
                            text: paragraph['protocol_paragraph.text']
                        }
                    }
                };
                this.store
                    .select(fromStore.getModelEntry(), paragraph.id)
                    .pipe(
                        filter(entry => !!entry),
                        take(1)
                    )
                    .subscribe(getModelRO => notifyGetModelRO$.next(getModelRO));
            });
    }

    handleSwitchView(payload, paragraphId = null) {
        this.store.dispatch(
            new fromStore.UpdateCurrentView({
                view: payload,
                paragraphId,
                scrollPos: this.scrollPos
            })
        );

        if (window.sessionStorage) {
            sessionStorage.setItem('view-' + this.id + '-' + this.version, payload);
        }

        if (payload === 'populations') {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { viewByPopulation: 'yes' },
                queryParamsHandling: 'merge',
                replaceUrl: true
            });
        } else {
            if (this.route.snapshot.queryParams['viewByPopulation']) {
                this.router.navigate([], { queryParams: { viewByPopulation: null }, queryParamsHandling: 'merge' });
            }
        }
    }

    handleLoadProtocolArticle(payload) {
        const { protocolId, articleId } = payload;
        this.store.dispatch(new fromStore.LoadProtocolArticle({ protocolId, articleId }));
    }

    handleImportModelBySheetId(payload) {
        const { protocolId, parentId, sheetId } = payload;
        this.store.dispatch(new fromStore.ImportModelBySheetId({ protocolId, parentId, sheetId }));
    }

    handleUpdateModelBySheetId(payload) {
        const { protocolId, parentId, ppdId, sheetId, action } = payload;
        this.store.dispatch(new fromStore.UpdateModelBySheetId({ protocolId, parentId, ppdId, sheetId, action }));
    }

    handleCreateCalculationFormula(payload) {
        const { parentId, ppdId } = payload;
        this.store.dispatch(new fromStore.CreateCalculationFormula({ parentId, ppdId }));
    }

    handleAddNonPubmedReference(payload) {
        const { protocolId, ppdId, parentId, url, anchorText, author, year, title } = payload;
        this.store.dispatch(
            new fromStore.AddNonPubmedReference({ protocolId, ppdId, parentId, url, anchorText, author, year, title })
        );
    }

    private handleImportReferences(payload) {
        const { sheetId, protocolId, ppdId } = payload;
        this.ParagraphsService.importReferences(payload).subscribe(
            () => this.store.dispatch(new fromStore.LoadProtocol({ protocolId, versionId: null, version: 'Draft' })));
    }

    handleAddReference(payload) {
        const { protocolId, parentId, ppdId, articleIds, footnote, findingIds } = payload;
            this.store.dispatch(
                new fromStore.AddReferenceToParagraph({
                    protocolId,
                    parentId,
                    ppdId,
                    articleIds,
                    findingIds,
                    footnote
                })
            );

        this.store.dispatch(new fromStore.ClearPubmeds());
    }

    handleChangeTypeOfPar(payload) {
        const { protocolId, ppdId, action, parentId } = payload;
        this.store.dispatch(new fromStore.UpdateParagraph({ protocolId, ppdId, parentId, options: { action } }));
    }

    handleGetPubmedSearch(payload) {
        const { parentId, ppdId, query, footnote } = payload;
        this.store.dispatch(new fromStore.LoadPubmeds({ parentId, ppdId, query, footnote }));
    }

    handleUploadFile(payload) {
        const { formData, protocolId, ppdId, title } = payload;
        this.store.dispatch(new fromStore.UploadFileToBucket({ formData, protocolId, ppdId, title }));
    }

    handleDeleteFile(payload) {
        const { protocolId, ppdId, fileId } = payload;
        this.store.dispatch(new fromStore.DeleteFile({ protocolId, ppdId, fileId }));
    }

    handleSubmitCreateGrade(payload) {
        this.store.dispatch(new fromStore.CreateGradeValue(payload));
    }

    handleSubmitUpdateGrade(payload) {
        this.store.dispatch(new fromStore.UpdateGradeValue(payload));
    }

    handleDeleteGrade(payload) {
        this.store.dispatch(new fromStore.DeleteGrade(payload));
    }


    handleSelectGradeQuestions(payload) {
        const { selectedQuestionField } = payload;
        this.store.dispatch(new fromStore.SelectGradeQuestion({ selectedQuestionField }));
    }

    handleGetGradeQuestions(payload) {
        const { gradeId, protocolId, ppdId = false, populationId = false } = payload;
        const { outPpdId, gradeType = 'ppdId' } = populationId
            ? {
                  outPpdId: populationId,
                  gradeType: 'population'
              }
            : {
                  outPpdId: ppdId,
                  gradeType: 'ppd'
              };

        this.store.dispatch(new fromStore.LoadGrades({ protocolId, ppdId: outPpdId, gradeType, gradeId }));
    }

    handleLoadEvidence(payload) {
        const { protocolId, ppdId, parentId} = payload;
        this.store.dispatch(new fromStore.LoadEvidence({ protocolId, ppdId, parentId}));
    }


    handleGradeUpdateRowAction(payload) {
        this.store.dispatch(new fromStore.UpdateGradeRow(payload));
    }

    handleSubmitProtocolActionFlow(payload) {
        const { action, versionId, protocolId = false, categoryId, comment = false } = payload;
        this.store.dispatch(new fromStore.SubmitFlowAction({ action, protocolId, categoryId, comment, versionId }));
    }

    handleOpenPane(payload) {
        this.pane = payload.name;
    }

    handleUpdateSetting(payload) {
        this.store.dispatch(
            new fromStore.UpdateSetting({
                protocolId: this.id,
                options: { ...payload, versionId: this.versionId, version: this.version }
            })
        );
    }

    handleParagraphSelected(payload) {
        const { paragraphId } = payload;
        this.handleSwitchView('text', paragraphId);
    }

    handleUploadFileToGrade(payload) {
        const { formData, reviewQuestion, title, ppdId } = payload;
        this.store.dispatch(new fromStore.UploadFileToBucket({ formData, reviewQuestion, ppdId, title }));
    }

    handleOpenByVersion(payload) {
        this.pane = 'content';
        this.version = payload.version;
        this.versionId = payload.versionId;

        // update the url
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: payload,
            queryParamsHandling: 'merge',
            replaceUrl: true
        });

        // load the data
        this.store.dispatch(
            new fromStore.LoadProtocol({ protocolId: this.id, versionId: payload.versionId, version: payload.version })
        );
    }

    handleOpenCompareVersion() {
        this.pane = 'content';
        // update the url
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {'compareVersion': true},
            queryParamsHandling: 'merge',
            replaceUrl: true
        });

        // load the data
        this.store.dispatch(
            new fromStore.LoadProtocol({ protocolId: this.id, version: 'Current', compareVersion: true })
        );
    }

    handleDeletePopulation(payload) {
        const { ppdId, outcomeId, populationId } = payload;

        this.store.dispatch(new fromStore.DeletePopulation({ ppdId, outcomeId, populationId }));
    }

    handleReplacePopulation(payload) {
        const { ppdId, outcomeId, oldPopulationId, newPopulationId, name } = payload;

        this.store.dispatch(
            new fromStore.ReplacePopulationStart({
                ppdId,
                outcomeId,
                oldPopulationId,
                newPopulationId,
                name
            })
        );
    }

    handleCopyPopulation(payload) {
        const { ppdId, outcome, population, findings } = payload;
        this.store.dispatch(new fromStore.CopyPopulation({ ppdId, outcome, population, findings }));
    }

    handleCreatePopulation(payload) {
        const { ppdId, outcome, findings } = payload;
        this.store.dispatch(new fromStore.CreatePopulation({ ppdId, outcome, findings }));
    }

    handleAddPopulation(payload) {
        const { ppdId, populationId, outcomeId, name } = payload;
        this.store.dispatch(new fromStore.UpdatePopulationName({ outcomeId, populationId, name }));
        this.store.dispatch(
            new fromStore.AddPopulation({
                ppdId,
                populationId,
                outcomeId
            })
        );
    }

    onSelectOutcome(payload) {
        this.store.dispatch(new fromStore.SelectOutcome(payload));
    }

    onSelectOutcomeList(payload) {
        this.store.dispatch(new fromStore.SelectOutcomeList(payload));
    }

    onShowMoreOutcomes(payload) {
        const { populationId, outcomeId, modelId } = payload;
        this.store.dispatch(
            new fromStore.SelectOutcomeList({
                populationId,
                outcomeId,
                modelId,
                action: 'showAllChildren'
            })
        );
    }

    handleUpdateParTitle(payload) {
        const {
            protocolId,
            ppdId,
            parentId,
            options: { title }
        } = payload;

        this.store.dispatch(new fromStore.UpdateParagraph({ protocolId, ppdId, parentId, options: { title } }));
    }

    handleDoneEditOrder(payload) {
        const { protocolId } = payload;
        this.store.dispatch(new fromStore.DoneEditSort({ protocolId }));
    }

    handleUpdateParPosition(payload) {
        const {
            protocolId,
            ppdId,
            parentId,
            options: { position }
        } = payload;
        this.store.dispatch(
            new fromStore.UpdateParPosition({
                protocolId,
                ppdId,
                parentId,
                position
            })
        );
    }

    handleAddPar(payload) {
        const { title, parentId, type } = payload;
        const protocolId = this.id + '';
        this.store.dispatch(new fromStore.AddParagraph({ protocolId, options: { title, type }, parentId }));
    }

    handleUpdateParToModel(payload) {
        const { ppdId, parentId } = payload;
        const protocolId = this.id + '';

        this.store.dispatch(new fromStore.UpdateParagraphToModel({ protocolId, ppdId, parentId }));
    }

    handleUpdateKeyQuestion(payload) {
        const { ppdId, parentId } = payload;
        const protocolId = this.id + '';
        const payloadData = {
            protocolId,
            ppdId,
            parentId,
            options: { action: 'updateKeyQuestion' }
        };
        this.store.dispatch(new fromStore.UpdateParagraph(payloadData));
        this.modalService.dismissAll();
    }

    handleCopyProtocol(_payload) {
        this.store.dispatch(new fromStore.CopyProtocol({ parentId: this.id + '' }));
    }


    handleLoadKeyQuestion(payload) {
        this.store.dispatch(new fromStore.LoadKeyQuestionImport(payload));
    }


    handleSubmitConnectKeyQuestion(payload) {
        const { protocolId, keyQuestionId, ppdId, parentId } = payload;
        const payloadData = {
            protocolId,
            ppdId,
            parentId,
            options: { keyQuestionId }
        };
        this.store.dispatch(new fromStore.UpdateParagraph(payloadData));
    }

    handleImportElementToSummary(payload) {
        const { protocolId, copyFromPpdId, parentId, type } = payload;
        this.store.dispatch(new fromStore.ImportElementToSummary({ protocolId, parentId, copyFromPpdId, type }));
    }

    handleAddFinding(payload) {
        const { protocolId, parentId, title, type } = payload;
        this.store.dispatch(new fromStore.AddFinding({ protocolId, parentId, title, type }));
    }

    handleRemoveFinding(payload) {
        const { protocolId, parentId, ppdId } = payload;
        this.store.dispatch(new fromStore.DeleteFinding({ protocolId, parentId, ppdId }));
    }

    handleAddOutcome(payload) {
        const { protocolId, parentId, title, type, min, max } = payload;
        this.store.dispatch(new fromStore.AddOutcome({ protocolId, parentId, title, type, min, max }));
    }

    handleEditModelOutcome(payload) {

        const { protocolId, ppdId, parentId, min, max } = payload;
        this.store.dispatch(new fromStore.UpdateOutcome({ protocolId, ppdId, parentId, min, max }));
    }

    handleDeletePar(payload) {
        const { protocolId, ppdId, parentId, grandParentId } = payload;
        this.store.dispatch(new fromStore.DeleteParagraph({ protocolId, ppdId, parentId, grandParentId }));
    }

    handleUpdateQuestionTitle(payload) {
        const {
            protocolId,
            ppdId,
            parentId,
            type,
            options: { title }
        } = payload;

        if (type === 'finding') {
            this.store.dispatch(new fromStore.UpdateFinding({ protocolId, ppdId, parentId, options: { title } }));
        } else if (type === 'outcome') {
            this.store.dispatch(new fromStore.UpdateOutcomeTitle({ protocolId, ppdId, parentId, title }));
        }
    }

    handleUpdatePopulationName(payload) {
        const { outcomeId, populationId, name } = payload;
        this.store.dispatch(new fromStore.UpdatePopulationName({ outcomeId, populationId, name }));
    }

    handleUpdateCopiedPopulationName(payload) {
        const { name } = payload;
        this.store.dispatch(new fromStore.UpdateCopiedPopulationName({ name }));
    }

    handleAddValueOption(payload) {
        const { protocolId, modelId, findingId, title, value, type } = payload;
        this.store.dispatch(new fromStore.AddValueOption({ protocolId, modelId, findingId, title, value, type }));
    }

    handleUpdateValueOption(payload) {
        const { protocolId, modelId, findingId, valueOptionId, title, value, type } = payload;
        this.store.dispatch(
            new fromStore.UpdateValueOption({ protocolId, modelId, findingId, valueOptionId, title, value, type })
        );
    }

    handleRemoveValueOption(payload) {
        const { protocolId, modelId, findingId, valueOptionId } = payload;
        this.store.dispatch(new fromStore.RemoveValueOption({ protocolId, modelId, findingId, valueOptionId }));
    }

    handleAddSliderOption(payload) {
        const { protocolId, modelId, findingId, type, min, max, step } = payload;
        this.store.dispatch(new fromStore.AddSlider({ protocolId, modelId, findingId, type, min, max, step }));
    }

    handleUpdateSliderOption(payload) {
        const { protocolId, modelId, findingId, valueOptionId, type, min, max, step } = payload;
        this.store.dispatch(
            new fromStore.UpdateSlider({ protocolId, modelId, findingId, valueOptionId, type, min, max, step })
        );
    }

    handleAddComment(payload) {
        const { protocolId, text, ppdId, parentId} = payload;
        this.store.dispatch(new fromStore.AddComment({ protocolId, ppdId, parentId, text}))
    }

    handleReplyComment(payload) {
        const { protocolId, text, comment, ppdId, parentId } = payload;
        this.store.dispatch(new fromStore.AddComment({ ppdId, comment, parentId, text}))
    }

    handleMarkComment(payload) {
        const { comment, ppdId, status, parentId} = payload;
        this.store.dispatch(new fromStore.AddComment({ ppdId, comment, parentId, status}))
    }

    handleClickIncludeSource(payload) {
        const { protocolId, ppdId, parentId, articleId, action, sourceId } = payload;
        this.store.dispatch(
            new fromStore.AddProtocolArticle({ protocolId, ppdId, parentId, articleId, action, sourceId })
        );
    }

    handleClickExcludeSource(payload) {
        const { protocolId, ppdId, parentId, articleId } = payload;
        this.store.dispatch(new fromStore.RemoveProtocolArticle({ protocolId, ppdId, parentId, articleId }));
    }

    handleUpdateModel(payload) {
        const { modelId, option, findingId, populationId, multiple, isCopiedPopulation } = payload;
        this.store.dispatch(
            new fromStore.UserUpdateModel({
                modelId,
                findingId,
                option,
                populationId,
                multiple,
                isCopiedPopulation
            })
        );
    }

    handleResetModel(payload) {
        const { modelId } = payload;
        this.store.dispatch(new fromStore.ResetModel({ modelId }));
    }

    handleShowAllOutcomes(payload) {
        const { modelId } = payload;
        this.store.dispatch(new fromStore.ShowAllOutcome({ modelId }));
    }

    handleShowOutcomesByPopulationId(payload) {
        const { populationId, modelId } = payload;
        this.store.dispatch(new fromStore.ShowOutcomesByPopulationId({ modelId, populationId }));
    }

    handleCreateLocalVersion(payload) {
        const { parentId } = payload;
        this.store.dispatch(new fromStore.CreateLocalProtocol({ parentId }));
    }

    handleLinkToProtocol(payload: { protocolId: string; ppdId: string; connectProtocolId: string }) {
        this.store.dispatch(new fromStore.LinkToProtocol(payload));
    }

    handleRemoveLinkToProtocol(payload: { paragraphId: string; ppdId: string}) {
        this.store.dispatch(new fromStore.RemoveLinkToProtocol(payload));
    }
    handleCreateFlowchart(payload) {
        this.store.dispatch(new fromStore.CreateFlowchart(payload));
    }

    handleDeleteFlowchart(payload) {
        this.store.dispatch(new fromStore.DeleteFlowchart(payload));
    }
}
